<template>
  <PageWrapper>
    <div class="w-full">
      <Header v-slot="{ _headerClass, _subheaderClass }">
        <h1 :class="_headerClass">Assets</h1>
        <p :class="_subheaderClass">
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eligendi
          fugit enim facere aliquid, vitae sapiente distinctio voluptatem ut.
          Quae, consequuntur!
        </p>
      </Header>
      <Table>
        <template #header="{ _class }">
          <div class="grid-cols-5" :class="_class">
            <div>Owner</div>
            <div>Category</div>
            <div>Type</div>
            <div>Value</div>
            <div>Description</div>
          </div>
        </template>
        <template #row="{ _outerClass, _innerClass, _buttonClass }">
          <div :class="_outerClass" v-for="asset in assets" :key="asset.id">
            <div class="grid-cols-5" :class="_innerClass">
              <div>{{ asset.owner }}</div>
              <div>{{ asset.category }}</div>
              <div>{{ asset.type }}</div>
              <div>{{ asset.value }}</div>
              <div>
                {{ asset.description }}
              </div>
            </div>
            <div :class="_buttonClass">
              <EditButton @click="displayEditAssetModal(asset.id)" />
              <DeleteButton />
            </div>
          </div>
        </template>
        <template
          #col="{
            _outerClass,
            _headerOuterClass,
            _headerClass,
            _btnClass,
            _innerClass,
            _cellOuterClass,
            _cellHeaderClass,
            _cellBodyClass,
          }"
        >
          <div :class="_outerClass" v-for="asset in assets" :key="asset.id">
            <div :class="_headerOuterClass">
              <h6 :class="_headerClass">{{ asset.type }}</h6>
              <div :class="_btnClass">
                <EditButton @click="displayEditAssetModal(asset.id)" />
                <DeleteButton />
              </div>
            </div>
            <div :class="_innerClass">
              <div :class="_cellOuterClass" class="border-t">
                <div :class="_cellHeaderClass">Owner</div>
                <div :class="_cellBodyClass">
                  {{ asset.owner }}
                </div>
              </div>
              <div :class="_cellOuterClass">
                <div :class="_cellHeaderClass">Category</div>
                <div :class="_cellBodyClass">
                  {{ asset.category }}
                </div>
              </div>
              <div :class="_cellOuterClass">
                <div :class="_cellHeaderClass">Value</div>
                <div :class="_cellBodyClass">
                  {{ asset.value }}
                </div>
              </div>
              <div :class="_cellOuterClass">
                <div :class="_cellHeaderClass">Description</div>
                <div :class="_cellBodyClass">
                  {{ asset.description }}
                </div>
              </div>
            </div>
          </div>
        </template>
      </Table>

      <!-- Add Asset Button -->
      <button
        class="mb-4 px-1 text-blue-500 font-medium text-sm flex justify-center items-center"
        @click="displayAddAssetModal"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="ai ai-ShoppingBag mr-1"
        >
          <path
            d="M3.977 9.84A2 2 0 0 1 5.971 8h12.058a2 2 0 0 1 1.994 1.84l.803 10A2 2 0 0 1 18.833 22H5.167a2 2 0 0 1-1.993-2.16l.803-10z"
          />
          <path d="M16 11V6a4 4 0 0 0-4-4v0a4 4 0 0 0-4 4v5" /></svg
        >Add Asset
      </button>

      <!-- Add Asset Modal -->
      <AddAsset :showModal="showAddAssetModal" @hideModal="hideAddAssetModal" />

      <!-- Edit Asset Modal -->
      <EditAsset
        :assetId="currentAsset"
        :showModal="showEditAssetModal"
        @hideModal="hideEditAssetModal"
      />

      <div class="w-full flex justify-end items-center">
        <router-link
          :to="{ name: 'income' }"
          class="btn-secondary py-2 w-24 rounded text-sm mx-2"
        >
          Back</router-link
        ><router-link
          :to="{ name: 'expenses' }"
          class="btn-primary text-sm py-2 w-24 rounded"
          >Continue</router-link
        >
      </div>
    </div>
  </PageWrapper>
</template>

<script>
import { reactive, toRefs } from "@vue/reactivity";
import useBenefitRecord from "../../composables/useBenefitRecord";
import PageWrapper from "../../components/home/layout/PageWrapper.vue";
import AddAsset from "../../components/home/ui/AddAsset.vue";
import EditAsset from "../../components/home/ui/EditAsset.vue";
import Table from "../../components/home/ui/Table.vue";
import EditButton from "../../components/home/ui/EditButton.vue";
import DeleteButton from "../../components/home/ui/DeleteButton.vue";
import Header from "../../components/home/ui/Header.vue";
export default {
  components: {
    PageWrapper,
    AddAsset,
    EditAsset,
    Table,
    EditButton,
    DeleteButton,
    Header,
  },
  setup() {
    const { assets } = useBenefitRecord();
    const state = reactive({
      showAddAssetModal: false,
      showEditAssetModal: false,
      currentAsset: "",
    });

    const displayAddAssetModal = () => {
      state.showAddAssetModal = true;
    };
    const hideAddAssetModal = () => {
      state.showAddAssetModal = false;
    };

    const displayEditAssetModal = (currentAsset) => {
      state.currentAsset = currentAsset;
      state.showEditAssetModal = true;
    };
    const hideEditAssetModal = () => {
      state.showEditAssetModal = false;
    };

    return {
      assets,
      ...toRefs(state),
      displayAddAssetModal,
      hideAddAssetModal,
      displayEditAssetModal,
      hideEditAssetModal,
    };
  },
};
</script>
